<template>
  <div class="problem">
    <div class="header">
      <div class="left">
        <van-icon name="arrow-left" @click="gopath" class="icon" />
      </div>
      <div class="title">{{ title }}</div>
    </div>
    <div class="detail" v-html="data"></div>
  </div>
</template>

<script>
import { problemdetail, agreement } from "@/api/user";
export default {
  data() {
    return {
      data: null,
      title: "",
    };
  },
  created() {
    if (!this.$route.query.title) {
      this.getList();
    } else {
      this.title = this.$route.query.title;
      this.getdetail();
    }
    1;
  },
  methods: {
    getList() {
      problemdetail(this.$route.query.commonProblemId).then((res) => {
        this.data = res.data;
        console.log(res);
      });
    },
    getdetail() {
      agreement(this.$route.query.title).then((res) => {
        this.data = res.data;
      });
    },
    gopath() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 10px 10px;
  display: flex;
  margin-bottom: 10px;
  .left {
    width: 10%;
    .icon {
      font-size: 24px;
      font-weight: 550;
      color: #141414;
    }
  }
  .title {
    width: 85%;
    text-align: center;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
  }
}
.detail {
  padding: 0 14px;
  font-size: 15px;
}
</style>